import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Thematic } from '../thematics.models';

export const thematicsActions = createActionGroup({
  source: 'Thematics',
  events: {
    'Load Single Thematic': props<{ uuid: string }>(),
    'Load Single Thematic Success': props<{ thematic: Thematic }>(),
    'Load Single Thematic Failure': props<{ error: Error }>(),
    'Load Thematics': emptyProps(),
    'Load Thematics Success': props<{ thematics: Thematic[] }>(),
    'Load Thematics Failure': props<{ error: Error }>()
  }
});
