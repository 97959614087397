import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Thematic } from '../thematics.models';

export const THEMATICS_FEATURE_KEY = 'thematics';

export interface ThematicsState extends EntityState<Thematic> {
  loading: boolean;
  error: Error | null;
}

export const thematicsAdapter = createEntityAdapter<Thematic>({
  selectId: (thematic: Thematic) => thematic.uuid
});

export const initialThematicsState = thematicsAdapter.getInitialState({
  loading: false,
  error: null
});
