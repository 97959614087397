import { MediaTypeEnum } from '@models/company/company';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectCurrentRoute } from 'app/store/router/router.selectors';
import { thematicsAdapter, ThematicsState, THEMATICS_FEATURE_KEY } from './thematics.state';

export const selectThematicsState = createFeatureSelector<ThematicsState>(THEMATICS_FEATURE_KEY);

export const entityAdapter = thematicsAdapter.getSelectors();

export const selectThematicEntities = createSelector(
  selectThematicsState,
  entityAdapter.selectEntities
);

export const selectThematics = createSelector(selectThematicsState, entityAdapter.selectAll);

export const selectCurrentThematic = createSelector(
  selectThematicEntities,
  selectCurrentRoute,
  (thematics, route) => (thematics ? thematics[route.params.uuid] : null)
);

export const selectThematicIsLoading = createSelector(selectThematicsState, state => state.loading);

export const selectCurrentThematicIframeMedias = createSelector(selectCurrentThematic, state =>
  state?.medias?.filter(m => m.type === MediaTypeEnum.IFRAME)
);
